/* BEGIN FONTS */
@font-face {
    font-family: 'Puritan';
    src: url(https://aminobelyamani.b-cdn.net/fonts/Puritan-Regular.ttf) format("truetype");;
    font-weight: 400;
}
@font-face {
    font-family: 'Puritan';
    src: url(https://aminobelyamani.b-cdn.net/fonts/Puritan-Bold.ttf) format("truetype");;
    font-weight: 700;
}
/* END FONTS */
/* BEGIN GLOBALS */
:root {
    --bckgdColor: #111;
    --footerColor:rgba(0 0 0 / 50%);
    --textColor:#fff;
    --textSecondaryColor:#b4b4b4;
    --hoverColor:#61f1f9;
    --errorColor:#ff9191;
    --mobilePadding: 12px;
    --vh: 1vh;
    --gridRepeat: 2;
}
*, :after, :before {
    -webkit-box-sizing: border-box;box-sizing: border-box;
}
.flex-container {
    display: -webkit-box; display: -ms-flexbox; display: flex;
}
.flex-center{
    -webkit-box-align: center; -ms-flex-align: center; align-items: center;
    -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.flex-col{
    -webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
}
.no-display{
    display:none!important;
}
.no-scroll{
    overflow:hidden;
}
.no-pointers{
    pointer-events: none!important;
}
.no-select{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.text-center{
    text-align: center;
}
.error{
    color:var(--errorColor);
}
.bckg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.btns{
    display: block;
    border:none;
    outline:none;
    border-radius: 5px;
    font-family: 'Puritan', sans-serif;
    cursor: pointer;
}
.ease-out {
    -o-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}
.ease-out-long {
    -o-transition: 1s ease-out;
    -moz-transition: 1s ease-out;
    -webkit-transition: 1s ease-out;
    transition: 1s ease-out;
}
.cubic-bezier{
    -o-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
}
.transform-footer{
    -o-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
.transform-header{
    -o-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
html, body {
    margin: 0;
    padding: 0;
    width:100%;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}
html {
    background-color: #111;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: 'Puritan', sans-serif;
}
/* END GLOBALS */
/* BEGIN HEADER & NAV */
.header{
    width:100%;
    height: 50px;
    position:fixed;
    padding:0 60px;
    align-items: center;
    -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
}
header{
    background-color: var(--bckgdColor);
    top:0;
    z-index: 4;
}
.logo:link, .logo:visited{
    text-decoration: none;
    color:var(--textColor);
    font-size: 2em;
    font-weight: 700;
}
nav{
    list-style: none;
}
nav ul li{
    list-style-type: none;
    display: inline-block;
}
.nav-items:link, .nav-items:visited{
    color:var(--textColor);
    text-decoration: none;
    margin-left:40px;
    font-size: 1em;
    font-weight: 700;
}
.nav-active{
    color:var(--hoverColor)!important;
}
.hamburger{
    display: none;
}
/* END HEADER & NAV */
/* BEGIN BACKGROUNDS */
#bckgd{
    background-image: url(https://aminobelyamani.b-cdn.net/images/home.jpg);
    width:100%;
    height: calc(100 * var(--vh));
    position: fixed;
    top:0;
    left:0;
    z-index: -1;
    overflow: hidden;
}
.was-change{
    -webkit-filter: grayscale(1); filter: grayscale(1);
}
.will-be-change{
    -webkit-filter:contrast(0.6);filter:contrast(0.6);
}
.shop-change{
    -webkit-filter:sepia(1); filter:sepia(1);
}
/* END BACKGROUNDS */
/* BEGIN FOOTER */
#pageFooter p{
    text-transform: lowercase;
}
.page-footer{
    width:100%;
    height: 50px;
    background-color: var(--footerColor);
    position:-webkit-sticky;
    position:sticky;
    bottom:0;
    z-index: 4;
}
.page-footer p {
    margin:0;
    color:var(--textColor);
    font-weight: 700;
}
footer{
    position: relative!important;
    background:transparent!important;
}
footer p{
    font-size: 0.7em;
    font-weight: 100!important;
}
/* END FOOTER */
/* BEGIN OVERLAYS */
.overlay{
    opacity:0.5;
    width:100vw;
    height:calc(100 * var(--vh));
    position:fixed;
    top:0;bottom:0;left:0;right:0;
    z-index: 10;
    background-color: rgba(0 0 0 / 75%);
    -ms-touch-action:pan-y;touch-action:pan-y;
    cursor: pointer;
}
#imgOverlay{
    visibility: hidden;
    position:fixed;
    top:50px;
    width:100vw;
    height:0vh;
    z-index: 99;
    -ms-touch-action:pan-y;touch-action:pan-y;
    cursor: pointer;
}
.image-overlay-change{
    height:calc(100 * var(--vh))!important;
}
.image-overlay-change img{
    width:var(--imgWidth)!important;
    height:var(--imgHeight)!important;
}
.overlay-wrapper{
    -ms-flex-item-align:start; align-self:flex-start;
    padding:0 30px;
}
#imgOverlay img{
    width:0;
    height:auto;
    max-width:1300px;
    border-radius: 5px;
    border:4px solid var(--textColor);
    -webkit-box-shadow:0px 1px 3px var(--textColor);box-shadow:0px 1px 3px var(--textColor);
}   
#imgCaption{
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    color:var(--textColor);
    margin-top:10px;
}
#resultOverlay{
    padding:0 20px;
}
.result-overlay-box{
    background-color: var(--bckgdColor);
    border-radius: 5px;
    padding:0;
    cursor: default;
    overflow: hidden;
    color:var(--hoverColor);
}
.result-overlay-content{
    padding:20px;
}
/* END OVERLAYS */
/* BEGIN MAIN CONTENT */
#main{
    min-height: calc(calc(100 * var(--vh)) - 50px);
    width:100%;
    max-width: 1400px;
    color:var(--textColor);
    position: relative;
    margin:0 auto;
    padding:50px 60px;
    opacity: 0;
}
.main-was{
    padding-top:100px!important;
 }
.content-block, .album-block{
    padding:40px 0;
    -webkit-column-gap:40px; -moz-column-gap:40px; column-gap:40px;
}
.content-block{
    border-bottom: 1px solid var(--textSecondaryColor);
}
.content-block-flex{
    width:50%;
    height: 100%;
}
.content-block-sticky{
    position:-webkit-sticky;
    position:sticky;
    top:90px;
}
.main-was .content-block-sticky{
    top:140px;
}
.title{
    font-size: 1.3em;
    margin:0;
}
.sub-title, .date{
    font-size: 1em;
    margin:0;
    font-weight: 100;
    color:var(--textSecondaryColor);
}
.read-text{
    margin:1.5em 0;
    font-size: 1em;
    line-height: 1.5em;
    text-transform: lowercase;
    color:var(--textSecondaryColor);
}
.images, .video-thumb{
    display: block;
    margin:20px auto 0;
    width:100%;
    -o-object-fit: cover;object-fit: cover;
    cursor: pointer;
}
.video-thumb{
    margin:0;
    height: 100%;
    -o-object-fit: contain;object-fit: contain;
    background-color: #000;
}
.hyperlinks:link, .hyperlinks:visited{
    color:var(--textColor);
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    -webkit-text-decoration-color: var(--hoverColor);text-decoration-color: var(--hoverColor);
}
.video-wrapper{
    position: relative;
    width:100%;
    height: 0;
    padding-top:56.25%;
    margin-bottom: 1em;
    cursor: pointer;
}
.video-wrapper iframe, .thumb-wrapper{
    position:absolute;
    top:0;left:0;right:0;bottom:0;
    width:100%;
    height: 100%;
}
.play-btn{
    position: absolute;
    width:50px;
    height:50px;
    background-image: url(https://aminobelyamani.b-cdn.net/images/play-btn.png);
    cursor: pointer;
    z-index: 1;
}
.video-title{
    position: absolute;
    max-width: calc(100% - 20px);
    top:10px;
    left:10px;
    margin:0;
    padding:10px;
    background-color: var(--footerColor);
    color:var(--textColor);
    border-radius: 5px;
    font-size: 1em;
    text-transform: lowercase;
}
.image-wrapper{
    grid-gap: 5px;
    display: -ms-grid; display: grid;
    grid-template-columns: repeat(var(--gridRepeat),50%);
    margin-bottom: 1em;
    position: relative;
}
.image-full-grid{
    -ms-grid-column: 1; -ms-grid-column-span: 2; grid-column: 1 / 3;
}
.image-wrapper img{
    margin:0!important;
    -ms-flex-item-align: stretch; -ms-grid-row-align: stretch; align-self: stretch;
}
/* END MAIN CONTENT */
/* BEGIN WAS HEADER */
#wasHeader{
    top:50px;
    background-color: var(--textColor);
    z-index: 3;
    padding:0 50px;
    overflow-x: scroll;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}
.was-header-item{
    padding:0 20px 0 0;
    -ms-flex-negative: 0;flex-shrink: 0;
    cursor: pointer;
}
.was-header-item p{
    color:var(--bckgdColor);
    font-weight: 700;
    margin:0;
    padding:10px;
    border-radius: 5px;
}
.was-header-item-change{
    background-color: var(--bckgdColor)!important;
    color:var(--textColor)!important;
}
/* END WAS HEADER */
/* BEGIN WILL-BE TABLE */
#showsTable{
    width:100%;
    background-color: rgba(0 0 0 / 75%);
    padding:0 20px 20px;
    border-radius: 0 0 5px 5px;
}
#showsTable tr{
   border-bottom: 1px solid #444446;
   padding:20px;
}
#showsTable td{
    color:var(--textSecondaryColor);
    margin:5px 0;
    text-transform: lowercase;
    text-align: center;
}
.td-venue{
    text-transform: uppercase!important;
    color:var(--textColor)!important;
    font-weight: 700;
}
.td-tickets{
    margin-top:20px!important;
    text-transform: uppercase!important;
    font-weight: 400;
    letter-spacing: 2px;
}
.td-tickets a:link, .td-tickets a:visited{
    color:var(--textColor);
    text-decoration: underline;
}
/* END WILL-BE TABLE */
/* BEGIN SHOP */
#shopWrapper{
    flex-wrap: wrap;
    gap:60px;
    margin-top:20px;
}
#shopWrapper h1{
    width:100%;
    margin:0 0 -40px 0;
}
#shopWrapper a{
    width:calc(33.33% - 40px);
}
#shopWrapper a:link, #shopWrapper a:visited{
    text-decoration: none;
}
.album-wrapper{
   background-color: rgb(255 255 255 / 10%);
   padding:20px;
   border-radius: 5px;
}
.album-thumb{
    width:100%;
}
.album-info-wrapper{
    padding:20px 0 0;
}
.album-info{
    color:var(--textColor);
    margin:0;
}
.album-artist{
    font-weight: 700;
    font-size: 1.2em;
}
.album-title{
    font-style: italic;
}
.album-price{
    margin-top:10px;
    color:var(--textSecondaryColor);
}
/* END SHOP */
/* BEGIN ALBUM PAGE */
.album-block img{
    display: block;
    width:100%;
}
.buy-link:link, .buy-link:visited{
    text-decoration: none;
    color:var(--bckgdColor);
}
.buy-link{
    display: inline-block;
    margin:20px 0 0;
    padding:5px 30px;
    font-weight: 700;
    border-radius: 5px;
    background-color: var(--hoverColor);
    -webkit-box-shadow: 0px 1px 5px var(--hoverColor); box-shadow: 0px 1px 5px var(--hoverColor);
}
.section-title{
    margin:0;
}
/* END ALBUM PAGE */
/* BEGIN CONTACT PAGE */
.content-block-transp{
    background-color: var(--footerColor);
    padding:20px;
    border-radius: 5px;
}
.border-bottom{
    border-bottom: 1px solid var(--bckgdColor);
    padding:20px 0;
}
.block-links:link, .block-links:visited{
    text-decoration: none;
    color:var(--textColor);
    text-transform: lowercase;
}
#contactForm{
    margin:0 auto 20px;
    width:100%;
}
.validate.input-fields:invalid{
    border:0.1px solid var(--errorColor);
}
.input-fields{
    display: block;
    border:none;
    outline:none;
    border-radius: 5px;
    width:100%;
    height: 40px;
    color:var(--textColor);
    background-color: var(--footerColor);
    margin:10px 0;
    padding:10px;
    font-family: 'Puritan', sans-serif;
    font-size: 1em;
}
.input-fields:hover, .input-fields:focus{
    background-color: rgba(0 0 0 / 75%);
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--textColor);
    -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
    box-shadow: 0 0 0px 1000px var(--footerColor) inset;
    -o-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
textarea{
    resize: none;
    min-height: 100px!important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--textSecondaryColor);
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--textSecondaryColor);
} 
::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--textSecondaryColor);
}
#submitBtn{
    width:100%;
    background-color: var(--textColor);
    color:var(--bckgdColor);
    padding:10px;
    font-size: 1.2em;
    font-weight: 700;
}
/* BEGIN SPINNER */
button:disabled {
    color:var(--textColor)!important;
    cursor: default!important;
}
.spinner,
.spinner:before {
    border-radius: 50%;
}
.spinner {
    color: var(--bckgdColor);
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 0 2px;box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    overflow:hidden;
}
.spinner:before{
    position: absolute;
    content: "";
}
.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--textColor);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 1s infinite linear;
}
@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* END SPINNER */
/* END CONTACT PAGE */
@media(max-width:768px){
    .header,  #wasHeader{
        padding:0 var(--mobilePadding);
    }
    nav{
       position:absolute;
       right:0;
       top:50px;
       width:200px;
       background-color: rgba(0 0 0 / 90%);
       border-radius: 0 0 0 10px;
       -o-transform:translateX(100%);
       -moz-transform:translateX(100%);
       -ms-transform:translateX(100%);
       -webkit-transform:translateX(100%);
       transform:translateX(100%);
    }
    .nav-transform{
        -o-transform:none!important;
        -moz-transform:none!important;
        -ms-transform:none!important;
        -webkit-transform:none!important;
        transform:none!important;
    }
    nav ul{
        display: -webkit-box; display: -ms-flexbox; display: flex;
        -webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;
        -webkit-box-align: center; -ms-flex-align: center; align-items: center;
        padding:0;
    }
    nav ul li{
        margin:20px 0;
    }
    .nav-items:link{
        margin:0;
    }
    .hamburger {
    	display: inline-block;
    	cursor: pointer;
	}
	.bar1, .bar3 {
    	width: 35px;
    	height: 3px;
    	background-color: var(--textColor);
    	margin: 6px 0;
	}
	.change .bar1 {
    	-o-transform: rotate(-22.5deg) translate(-9px, 6px);
    	-moz-transform: rotate(-22.5deg) translate(-9px, 6px);
    	-ms-transform: rotate(-22.5deg) translate(-9px, 6px);
    	-webkit-transform: rotate(-22.5deg) translate(-9px, 6px);
    	transform: rotate(-22.5deg) translate(-9px, 6px);
	}
	.change .bar3 {
    	-o-transform: rotate(22.5deg);
    	-moz-transform: rotate(22.5deg);
    	-ms-transform: rotate(22.5deg);
    	-webkit-transform: rotate(22.5deg);
    	transform: rotate(22.5deg);
	}
    #main{
        padding:50px var(--mobilePadding);
    }
    .content-block, .album-block{
        display: block;
        padding:10px 0;
    }
    .content-block-flex{
        width:100%;
    }
    .content-block-sticky{
        position: relative;
        top:unset!important;
    }
    #shopWrapper{
        gap:20px;
    }
    #shopWrapper a{
        width:calc(50% - 10px);
    }
    #shopWrapper h1{
        margin:10px 0 0 0;
    }
    .album-block .content-block-sticky{
        margin-bottom:20px;
    }
    #shopWrapper{
        margin-top:10px;
    }
}
@media(max-width:600px){
    #shopWrapper a{
        width:100%;
    }
}
/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .backdrop-filter{
        background-color: transparent!important;
        -webkit-backdrop-filter: blur(50px);backdrop-filter: blur(50px);
    }
    #showsTable{
        -webkit-backdrop-filter: blur(20px);backdrop-filter: blur(20px);
    }
    #pageFooter{
        -webkit-backdrop-filter: blur(10px);backdrop-filter: blur(10px);
    }
    @media(max-width:768px){
        nav{
            background-color: var(--footerColor);
            -webkit-backdrop-filter: blur(20px);backdrop-filter: blur(20px);
        }
    }
}
@media(hover:hover){
    .nav-items:hover, .nav-items:focus, .logo:hover, .logo:focus{
        color:var(--hoverColor);
    }
    .hyperlinks:hover, .hyperlinks:focus{
        -webkit-text-decoration-color: var(--textSecondaryColor);text-decoration-color: var(--textSecondaryColor);
    }
    .video-wrapper:hover .video-thumb, .video-wrapper:focus .video-thumb{
        -webkit-filter:blur(5px);filter:blur(5px);
    }
    .was-header-item:hover p{
        background-color: var(--bckgdColor);
        color:var(--textColor);
    }
    .td-tickets a:hover, .td-tickets a:focus{
        color:var(--hoverColor);
    }
    #shopWrapper a:hover .album-wrapper{
        background-color: #444444;
    }
    .buy-link:hover, .buy-link:focus{
        background-color: var(--hoverColor);
        -webkit-box-shadow: 0px 1px 10px var(--hoverColor); box-shadow: 0px 1px 10px var(--hoverColor);
    }
    .block-links:hover, .block-links:focus{
        text-decoration: underline;
        -webkit-text-decoration-color: var(--hoverColor); text-decoration-color: var(--hoverColor);
        text-decoration-thickness: 3px;
        font-weight: 700;
    }
    #submitBtn:hover, #submitBtn:focus{
        -webkit-box-shadow: 0px 1px 10px var(--textColor); box-shadow: 0px 1px 10px var(--textColor);
    }
}