@font-face {
  font-family: Puritan;
  src: url("https://aminobelyamani.b-cdn.net/fonts/Puritan-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Puritan;
  src: url("https://aminobelyamani.b-cdn.net/fonts/Puritan-Bold.ttf") format("truetype");
  font-weight: 700;
}

:root {
  --bckgdColor: #111;
  --footerColor: #00000080;
  --textColor: #fff;
  --textSecondaryColor: #b4b4b4;
  --hoverColor: #61f1f9;
  --errorColor: #ff9191;
  --mobilePadding: 12px;
  --vh: 1vh;
  --gridRepeat: 2;
}

*, :after, :before {
  box-sizing: border-box;
}

.flex-container {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.no-display {
  display: none !important;
}

.no-scroll {
  overflow: hidden;
}

.no-pointers {
  pointer-events: none !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.text-center {
  text-align: center;
}

.error {
  color: var(--errorColor);
}

.bckg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btns {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  font-family: Puritan, sans-serif;
  display: block;
}

.ease-out {
  -o-transition: .3s ease-out;
  transition: all .3s ease-out;
}

.ease-out-long {
  -o-transition: 1s ease-out;
  transition: all 1s ease-out;
}

.cubic-bezier {
  -o-transition: .7s cubic-bezier(.57, -.21, 0, .74);
  transition: all .7s cubic-bezier(.57, -.21, 0, .74);
}

.transform-footer {
  transform: translateY(100%);
}

.transform-header {
  transform: translateY(-100%);
}

html, body {
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #111;
  font-family: Puritan, sans-serif;
}

.header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 60px;
  position: fixed;
}

header {
  background-color: var(--bckgdColor);
  z-index: 4;
  top: 0;
}

.logo:link, .logo:visited {
  color: var(--textColor);
  font-size: 2em;
  font-weight: 700;
  text-decoration: none;
}

nav {
  list-style: none;
}

nav ul li {
  list-style-type: none;
  display: inline-block;
}

.nav-items:link, .nav-items:visited {
  color: var(--textColor);
  margin-left: 40px;
  font-size: 1em;
  font-weight: 700;
  text-decoration: none;
}

.nav-active {
  color: var(--hoverColor) !important;
}

.hamburger {
  display: none;
}

#bckgd {
  height: calc(100 * var(--vh));
  z-index: -1;
  background-image: url("https://aminobelyamani.b-cdn.net/images/home.jpg");
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.was-change {
  filter: grayscale();
}

.will-be-change {
  filter: contrast(.6);
}

.shop-change {
  filter: sepia();
}

#pageFooter p {
  text-transform: lowercase;
}

.page-footer {
  background-color: var(--footerColor);
  z-index: 4;
  width: 100%;
  height: 50px;
  position: sticky;
  bottom: 0;
}

.page-footer p {
  color: var(--textColor);
  margin: 0;
  font-weight: 700;
}

footer {
  background: none !important;
  position: relative !important;
}

footer p {
  font-size: .7em;
  font-weight: 100 !important;
}

.overlay {
  opacity: .5;
  height: calc(100 * var(--vh));
  z-index: 10;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  cursor: pointer;
  background-color: #000000bf;
  width: 100vw;
  position: fixed;
  inset: 0;
}

#imgOverlay {
  visibility: hidden;
  z-index: 99;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  cursor: pointer;
  width: 100vw;
  height: 0;
  position: fixed;
  top: 50px;
}

.image-overlay-change {
  height: calc(100 * var(--vh)) !important;
}

.image-overlay-change img {
  width: var(--imgWidth) !important;
  height: var(--imgHeight) !important;
}

.overlay-wrapper {
  align-self: flex-start;
  padding: 0 30px;
}

#imgOverlay img {
  border: 4px solid var(--textColor);
  -webkit-box-shadow: 0px 1px 3px var(--textColor);
  box-shadow: 0px 1px 3px var(--textColor);
  border-radius: 5px;
  width: 0;
  max-width: 1300px;
  height: auto;
}

#imgCaption {
  text-align: center;
  color: var(--textColor);
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: 700;
}

#resultOverlay {
  padding: 0 20px;
}

.result-overlay-box {
  background-color: var(--bckgdColor);
  cursor: default;
  color: var(--hoverColor);
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
}

.result-overlay-content {
  padding: 20px;
}

#main {
  min-height: calc(calc(100 * var(--vh))  - 50px);
  color: var(--textColor);
  opacity: 0;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px 60px;
  position: relative;
}

.main-was {
  padding-top: 100px !important;
}

.content-block, .album-block {
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
  padding: 40px 0;
}

.content-block {
  border-bottom: 1px solid var(--textSecondaryColor);
}

.content-block-flex {
  width: 50%;
  height: 100%;
}

.content-block-sticky {
  position: sticky;
  top: 90px;
}

.main-was .content-block-sticky {
  top: 140px;
}

.title {
  margin: 0;
  font-size: 1.3em;
}

.sub-title, .date {
  color: var(--textSecondaryColor);
  margin: 0;
  font-size: 1em;
  font-weight: 100;
}

.read-text {
  text-transform: lowercase;
  color: var(--textSecondaryColor);
  margin: 1.5em 0;
  font-size: 1em;
  line-height: 1.5em;
}

.images, .video-thumb {
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  margin: 20px auto 0;
  display: block;
}

.video-thumb {
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #000;
  height: 100%;
  margin: 0;
}

.hyperlinks:link, .hyperlinks:visited {
  color: var(--textColor);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  -webkit-text-decoration-color: var(--hoverColor);
  -webkit-text-decoration-color: var(--hoverColor);
  text-decoration-color: var(--hoverColor);
  font-weight: 700;
}

.video-wrapper {
  cursor: pointer;
  width: 100%;
  height: 0;
  margin-bottom: 1em;
  padding-top: 56.25%;
  position: relative;
}

.video-wrapper iframe, .thumb-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.play-btn {
  cursor: pointer;
  z-index: 1;
  background-image: url("https://aminobelyamani.b-cdn.net/images/play-btn.png");
  width: 50px;
  height: 50px;
  position: absolute;
}

.video-title {
  background-color: var(--footerColor);
  color: var(--textColor);
  text-transform: lowercase;
  border-radius: 5px;
  max-width: calc(100% - 20px);
  margin: 0;
  padding: 10px;
  font-size: 1em;
  position: absolute;
  top: 10px;
  left: 10px;
}

.image-wrapper {
  grid-gap: 5px;
  display: -ms-grid;
  grid-template-columns: repeat(var(--gridRepeat), 50%);
  margin-bottom: 1em;
  display: grid;
  position: relative;
}

.image-full-grid {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.image-wrapper img {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin: 0 !important;
}

#wasHeader {
  background-color: var(--textColor);
  z-index: 3;
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 0 50px;
  top: 50px;
  overflow-x: scroll;
}

.was-header-item {
  cursor: pointer;
  flex-shrink: 0;
  padding: 0 20px 0 0;
}

.was-header-item p {
  color: var(--bckgdColor);
  border-radius: 5px;
  margin: 0;
  padding: 10px;
  font-weight: 700;
}

.was-header-item-change {
  background-color: var(--bckgdColor) !important;
  color: var(--textColor) !important;
}

#showsTable {
  background-color: #000000bf;
  border-radius: 0 0 5px 5px;
  width: 100%;
  padding: 0 20px 20px;
}

#showsTable tr {
  border-bottom: 1px solid #444446;
  padding: 20px;
}

#showsTable td {
  color: var(--textSecondaryColor);
  text-transform: lowercase;
  text-align: center;
  margin: 5px 0;
}

.td-venue {
  font-weight: 700;
  text-transform: uppercase !important;
  color: var(--textColor) !important;
}

.td-tickets {
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase !important;
  margin-top: 20px !important;
}

.td-tickets a:link, .td-tickets a:visited {
  color: var(--textColor);
  text-decoration: underline;
}

#shopWrapper {
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 20px;
}

#shopWrapper h1 {
  width: 100%;
  margin: 0 0 -40px;
}

#shopWrapper a {
  width: calc(33.33% - 40px);
}

#shopWrapper a:link, #shopWrapper a:visited {
  text-decoration: none;
}

.album-wrapper {
  background-color: #ffffff1a;
  border-radius: 5px;
  padding: 20px;
}

.album-thumb {
  width: 100%;
}

.album-info-wrapper {
  padding: 20px 0 0;
}

.album-info {
  color: var(--textColor);
  margin: 0;
}

.album-artist {
  font-size: 1.2em;
  font-weight: 700;
}

.album-title {
  font-style: italic;
}

.album-price {
  color: var(--textSecondaryColor);
  margin-top: 10px;
}

.album-block img {
  width: 100%;
  display: block;
}

.buy-link:link, .buy-link:visited {
  color: var(--bckgdColor);
  text-decoration: none;
}

.buy-link {
  background-color: var(--hoverColor);
  -webkit-box-shadow: 0px 1px 5px var(--hoverColor);
  box-shadow: 0px 1px 5px var(--hoverColor);
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 5px 30px;
  font-weight: 700;
  display: inline-block;
}

.section-title {
  margin: 0;
}

.content-block-transp {
  background-color: var(--footerColor);
  border-radius: 5px;
  padding: 20px;
}

.border-bottom {
  border-bottom: 1px solid var(--bckgdColor);
  padding: 20px 0;
}

.block-links:link, .block-links:visited {
  color: var(--textColor);
  text-transform: lowercase;
  text-decoration: none;
}

#contactForm {
  width: 100%;
  margin: 0 auto 20px;
}

.validate.input-fields:invalid {
  border: .1px solid var(--errorColor);
}

.input-fields {
  color: var(--textColor);
  background-color: var(--footerColor);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 10px;
  font-family: Puritan, sans-serif;
  font-size: 1em;
  display: block;
}

.input-fields:hover, .input-fields:focus {
  background-color: #000000bf;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  box-shadow: 0 0 0px 1000px var(--footerColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea {
  resize: none;
  min-height: 100px !important;
}

::placeholder {
  color: var(--textSecondaryColor);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--textSecondaryColor);
}

::-moz-placeholder {
  color: var(--textSecondaryColor);
}

#submitBtn {
  background-color: var(--textColor);
  color: var(--bckgdColor);
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  font-weight: 700;
}

button:disabled {
  color: var(--textColor) !important;
  cursor: default !important;
}

.spinner, .spinner:before {
  border-radius: 50%;
}

.spinner {
  color: var(--bckgdColor);
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.spinner:before {
  content: "";
  background: var(--textColor);
  transform-origin: 10.4px 10.2px;
  -webkit-animation: 2s 1.5s infinite loading;
  border-radius: 20.4px 0 0 20.4px;
  width: 10.4px;
  height: 20.4px;
  animation: 1s linear infinite loading;
  position: absolute;
  top: -.2px;
  left: -.2px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (width <= 768px) {
  .header, #wasHeader {
    padding: 0 var(--mobilePadding);
  }

  nav {
    background-color: #000000e6;
    border-radius: 0 0 0 10px;
    width: 200px;
    position: absolute;
    top: 50px;
    right: 0;
    transform: translateX(100%);
  }

  .nav-transform {
    transform: none !important;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
    display: flex;
  }

  nav ul li {
    margin: 20px 0;
  }

  .nav-items:link {
    margin: 0;
  }

  .hamburger {
    cursor: pointer;
    display: inline-block;
  }

  .bar1, .bar3 {
    background-color: var(--textColor);
    width: 35px;
    height: 3px;
    margin: 6px 0;
  }

  .change .bar1 {
    transform: rotate(-22.5deg)translate(-9px, 6px);
  }

  .change .bar3 {
    transform: rotate(22.5deg);
  }

  #main {
    padding: 50px var(--mobilePadding);
  }

  .content-block, .album-block {
    padding: 10px 0;
    display: block;
  }

  .content-block-flex {
    width: 100%;
  }

  .content-block-sticky {
    position: relative;
    top: unset !important;
  }

  #shopWrapper {
    gap: 20px;
  }

  #shopWrapper a {
    width: calc(50% - 10px);
  }

  #shopWrapper h1 {
    margin: 10px 0 0;
  }

  .album-block .content-block-sticky {
    margin-bottom: 20px;
  }

  #shopWrapper {
    margin-top: 10px;
  }
}

@media (width <= 600px) {
  #shopWrapper a {
    width: 100%;
  }
}

@supports ((-webkit-backdrop-filter: none)) or ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-filter {
    backdrop-filter: blur(50px);
    background-color: #0000 !important;
  }

  #showsTable {
    backdrop-filter: blur(20px);
  }

  #pageFooter {
    backdrop-filter: blur(10px);
  }

  @media (width <= 768px) {
    nav {
      background-color: var(--footerColor);
      backdrop-filter: blur(20px);
    }
  }
}

@media (hover: hover) {
  .nav-items:hover, .nav-items:focus, .logo:hover, .logo:focus {
    color: var(--hoverColor);
  }

  .hyperlinks:hover, .hyperlinks:focus {
    -webkit-text-decoration-color: var(--textSecondaryColor);
    -webkit-text-decoration-color: var(--textSecondaryColor);
    text-decoration-color: var(--textSecondaryColor);
  }

  .video-wrapper:hover .video-thumb, .video-wrapper:focus .video-thumb {
    filter: blur(5px);
  }

  .was-header-item:hover p {
    background-color: var(--bckgdColor);
    color: var(--textColor);
  }

  .td-tickets a:hover, .td-tickets a:focus {
    color: var(--hoverColor);
  }

  #shopWrapper a:hover .album-wrapper {
    background-color: #444;
  }

  .buy-link:hover, .buy-link:focus {
    background-color: var(--hoverColor);
    -webkit-box-shadow: 0px 1px 10px var(--hoverColor);
    box-shadow: 0px 1px 10px var(--hoverColor);
  }

  .block-links:hover, .block-links:focus {
    text-decoration: underline;
    -webkit-text-decoration-color: var(--hoverColor);
    -webkit-text-decoration-color: var(--hoverColor);
    text-decoration-color: var(--hoverColor);
    font-weight: 700;
    text-decoration-thickness: 3px;
  }

  #submitBtn:hover, #submitBtn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--textColor);
    box-shadow: 0px 1px 10px var(--textColor);
  }
}

/*# sourceMappingURL=aicha.754496f9.css.map */
